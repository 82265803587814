<template>
  <div>
    <sidebar homepage="Entry">
      <!-- ==================== Manage Project Mortgage ==================== -->
      <sidebar-item
        label="Manage Bank Loan "
        iconClass="fas fa-hand-holding-usd"
        :to="{ name: 'ManageBankLoans' }"
      ></sidebar-item>
      <sidebar-item
        label="Bank Loan History"
        iconClass="fas fa-history"
        :to="{ name: 'ManageBankLoansHistory' }"
      ></sidebar-item>
      <!-- ======================== Manage Profile ========================= -->
      <!-- <sidebar-separator label="Profile"></sidebar-separator> -->
      <sidebar-item
        label="Profile"
        iconClass="fas fa-user-circle"
        :to="{ name: 'ManageProfile' }"
      ></sidebar-item>
    </sidebar>
  </div>
</template>

<script>
export default {
  components: {
    Sidebar: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/Sidebar.vue"
      ),
    SidebarItem: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarItem.vue"
      )
    // SidebarDropdown: () =>
    //   import(
    //     "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarDropdown.vue"
    //   ),
    // SidebarSeparator: () =>
    //   import(
    //     "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarSeparator.vue"
    //   )
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      //
    }
  }
};
</script>

<style lang="scss" scoped></style>
